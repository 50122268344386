import { Button, IconButton, Tooltip, Typography } from "@mui/material";
import { makeStyles, ThemeProvider } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import Loading from "../../components/Loading";
import MUIDataTable from "mui-datatables";
import TableOptions from "../../components/TableOptions";
import { getElements } from "../../database/API";
import Title from "../../components/Title";
import PostColumns from "./Table/PostColumns";
import { Plus, RefreshCcw } from "react-feather";
import getMuiTheme from "../../theme/TableStyle";
import DialogPost from "./Components/DialogPost";

export default function Posts() {
	const classes = useStyles();
	const [posts, setPosts] = useState([]);
	const [loading, setLoading] = useState(true);
	const [update, setUpdate] = useState(0);
	const [open, setOpen] = useState(false);
	const [postID, setPostID] = useState(false);
	const columns = PostColumns({ posts, setPostID, setOpen });

	useEffect(() => {
		setLoading(true);
		const get = async () => {
			const { result } = await getElements("post");
			setPosts(result);
			setLoading(false);
		};

		get();
	}, [update]);

	const CustomToolbar = () => {
		return (
			<Button
				onClick={() => navigate("/admin/activitats/add")}
				title={
					<>
						<Plus size={15} /> Nova Activitat
					</>
				}
			/>
		);
	};
	const options = TableOptions(CustomToolbar);

	return (
		<Box className={classes.root}>
			<Title
				title="Posts"
				button={
					<Tooltip title="Refresca">
						<IconButton
							onClick={() => setUpdate((prev) => prev + 1)}
						>
							<RefreshCcw />
						</IconButton>
					</Tooltip>
				}
			/>
			<Loading loading={loading}>
				<Box p={3}>
					<ThemeProvider theme={getMuiTheme()}>
						<MUIDataTable
							data={posts}
							columns={columns}
							options={options}
						/>
					</ThemeProvider>
				</Box>
			</Loading>
			<DialogPost
				open={open}
				setOpen={setOpen}
				id={postID}
				setUpdate={setUpdate}
			/>
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		zIndex: 1,
		height: "100vh",
	},
}));
