import React from "react";
import { Box, CircularProgress, Fade, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";

export default function Title({ title, button }) {
	const classes = useStyles();
	return (
		<Box pb={3} px={3}>
			<Box p={3} className={classes.block}>
				<Typography variant="h2">{title}</Typography>
				{button}
			</Box>
		</Box>
	);
}

Title.propTypes = {
	title: PropTypes.string,
	button: PropTypes.object,
};

const useStyles = makeStyles((theme) => ({
	block: {
		backgroundColor: "white",
		borderRadius: 10,
		padding: 10,
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
}));
