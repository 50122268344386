import { Button, IconButton, Tooltip, Typography } from "@mui/material";
import { makeStyles, ThemeProvider } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import Loading from "../../components/Loading";
import MUIDataTable from "mui-datatables";
import TableOptions from "../../components/TableOptions";
import { getElements } from "../../database/API";
import Title from "../../components/Title";
import { Plus } from "react-feather";
import getMuiTheme from "../../theme/TableStyle";
import DialogPunt from "./Components/DialogPunt";
import PuntColumns from "./Table/PuntColumns";

export default function Punts() {
	const classes = useStyles();
	const [punts, setPunts] = useState([]);
	const [loading, setLoading] = useState(true);
	const [update, setUpdate] = useState(0);
	const [open, setOpen] = useState(false);
	const [puntID, setPuntID] = useState(null);
	const columns = PuntColumns({ punts, setPuntID, setOpen });

	useEffect(() => {
		setLoading(true);
		const get = async () => {
			const { result } = await getElements("punt");
			setPunts(result);
			setLoading(false);
		};

		get();
	}, [update]);

	const CustomToolbar = () => {};
	const options = TableOptions(CustomToolbar);

	const nou = () => {
		setPuntID();
		setOpen(true);
	};

	return (
		<Box className={classes.root}>
			<Title
				title="Punts d'interès"
				button={
					<Tooltip title="Afegir punt d'interès">
						<IconButton onClick={nou}>
							<Plus />
						</IconButton>
					</Tooltip>
				}
			/>
			<Loading loading={loading}>
				<Box p={3}>
					<ThemeProvider theme={getMuiTheme()}>
						<MUIDataTable
							data={punts}
							columns={columns}
							options={options}
						/>
					</ThemeProvider>
				</Box>
			</Loading>
			<DialogPunt
				open={open}
				setOpen={setOpen}
				id={puntID}
				setUpdate={setUpdate}
			/>
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		zIndex: 1,
		height: "100vh",
	},
}));
