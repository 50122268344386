import { Button, Typography } from "@mui/material";
import { makeStyles, ThemeProvider } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import Loading from "../../components/Loading";
import MUIDataTable from "mui-datatables";
import { Plus } from "react-feather";
import EquipsColumns from "./Table/EquipsColumns";
import TableOptions from "../../components/TableOptions";
import { getElements } from "../../database/API";
import Title from "../../components/Title";
import getMuiTheme from "../../theme/TableStyle";

export default function Equips() {
	const classes = useStyles();
	const [equips, setEquips] = useState([]);
	const [loading, setLoading] = useState(true);
	const columns = EquipsColumns(equips);

	useEffect(() => {
		const get = async () => {
			const { result } = await getElements("equip");
			setEquips(result);
			setLoading(false);
		};

		get();
	}, []);

	const CustomToolbar = () => {
		return (
			<Button
				onClick={() => navigate("/admin/activitats/add")}
				title={
					<>
						<Plus size={15} /> Nova Activitat
					</>
				}
			/>
		);
	};
	const options = TableOptions(CustomToolbar);

	return (
		<Box className={classes.root}>
			<Title title="Equips" />
			<Loading loading={loading}>
				<Box p={3}>
					<ThemeProvider theme={getMuiTheme()}>
						<MUIDataTable
							data={equips}
							columns={columns}
							options={options}
						/>
					</ThemeProvider>
				</Box>
			</Loading>
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		background: "rgb(15,89,45)",
		background: "linear-gradient(0deg, rgba(15,89,45,1) 0%, #4FB543 100%)",
		height: "100vh",
		width: "100%",
	},
}));
