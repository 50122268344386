import * as React from "react";
import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";
import PropTypes from "prop-types";

const Custom = styled(TextField)({
	marginTop: 20,
	"& .MuiInputLabel-root": {
		// fontSize: 14,
	},
});

export default function CustomTextField({
	errors,
	label,
	name,
	type,
	register,
	size,
	...rest
}) {
	return (
		<Custom
			error={Boolean(errors)}
			helperText={errors?.message}
			fullWidth
			label={label}
			name={name}
			type={type}
			variant="outlined"
			{...register(name)}
			{...rest}
		/>
	);
}

CustomTextField.propTypes = {
	errors: PropTypes.array,
	label: PropTypes.string,
	name: PropTypes.string,
	type: PropTypes.string,
	register: PropTypes.func,
	size: PropTypes.number,
};
