import {
	Avatar,
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControlLabel,
	FormGroup,
	TextField,
	Typography,
} from "@mui/material";
import { makeStyles, ThemeProvider } from "@mui/styles";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
	eliminarPost,
	getElement,
	publicar,
	updateIncidence,
} from "../../../database/API";
import { useSnackbar } from "notistack";

export default function DialogIncidences({ open, setOpen, id, setUpdate }) {
	const classes = useStyles();
	const [incidence, setIncidence] = useState([]);
	const [loading, setLoading] = useState(true);
	const [resposta, setResposta] = useState("");
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		const get = async () => {
			setLoading(true);
			const { result } = await getElement("incidences", id);
			setIncidence(result);
			setLoading(false);
		};

		get();
	}, [id]);

	const respondre = async () => {
		// console.log(resposta);
		const message = await updateIncidence(
			{ resposta: resposta },
			incidence.id
		);
		setOpen(false);
		setUpdate((prev) => prev + 1);
		enqueueSnackbar(message, {
			variant: "success",
		});
	};

	const eliminar = async () => {
		const message = await eliminarPost(
			incidence?.id,
			incidence?.equip?.num_reserva
		);
		setOpen(false);
		setUpdate((prev) => prev + 1);
		enqueueSnackbar(message, {
			variant: "success",
		});
	};

	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			maxWidth="lg"
		>
			<DialogTitle id="alert-dialog-title">
				Respondre incidència
			</DialogTitle>
			<DialogContent>
				{loading ? (
					<CircularProgress />
				) : (
					<Box>
						<Box
							display={"flex"}
							alignItems="center"
							alignContent={"center"}
							mb={3}
						>
							<Avatar
								src={
									"https://greenchallenge.covcontrol.net/public/storage/" +
									incidence?.equip?.foto
								}
							/>
							<Typography variant="h3" ml={2}>
								{incidence?.equip?.nom}
							</Typography>
						</Box>
						<Box p={3} mb={4} className={classes.missatge}>
							<Typography variant="h3">
								{incidence?.assumpte}
							</Typography>

							<Typography variant="body1">
								{incidence?.missatge}
							</Typography>
						</Box>
						<TextField
							label="Resposta"
							type="text"
							multiline
							rows={3}
							variant="outlined"
							fullWidth
							value={resposta}
							onChange={(e) => setResposta(e.target.value)}
						/>
					</Box>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={eliminar} color="warning">
					Eliminar incidència
				</Button>
				<Button onClick={() => setOpen(false)} color="warning">
					Tancar
				</Button>
				<Button onClick={respondre} autoFocus>
					Enviar resposta
				</Button>
			</DialogActions>
		</Dialog>
	);
}

DialogIncidences.propTypes = {
	open: PropTypes.bool,
	setOpen: PropTypes.func,
	setUpdate: PropTypes.func,
	id: PropTypes.number,
};

const useStyles = makeStyles((theme) => ({
	root: {
		background: "rgb(15,89,45)",
		background: "linear-gradient(0deg, rgba(15,89,45,1) 0%, #4FB543 100%)",
		height: "100vh",
		width: "100%",
	},
	missatge: {
		border: "1px solid rgb(15,89,45)",
		borderRadius: 5,
	},
}));
