import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { ReactComponent as Estrella } from "../assets/icons/estrella.svg";
import { ReactComponent as EstrellaO } from "../assets/icons/estrella-o.svg";
import { makeStyles } from "@mui/styles";
import theme from "../theme";

export default function Stars({ stars, setEstrelles }) {
	const classes = useStyles();
	return (
		<Box className={classes.wrapStars}>
			<Box
				style={{
					width: 23,
				}}
				onClick={() => setEstrelles(1)}
			>
				{stars >= 1 ? (
					<Estrella fill={theme.palette.primary.star} />
				) : (
					<EstrellaO fill={theme.palette.primary.star} />
				)}
			</Box>
			<Box
				style={{
					width: 23,
				}}
				onClick={() => setEstrelles(2)}
			>
				{stars >= 2 ? (
					<Estrella fill={theme.palette.primary.star} />
				) : (
					<EstrellaO fill={theme.palette.primary.star} />
				)}
			</Box>
			<Box
				style={{
					width: 23,
				}}
				onClick={() => setEstrelles(3)}
			>
				{stars >= 3 ? (
					<Estrella fill={theme.palette.primary.star} />
				) : (
					<EstrellaO fill={theme.palette.primary.star} />
				)}
			</Box>
			<Box
				style={{
					width: 23,
				}}
				onClick={() => setEstrelles(4)}
			>
				{stars >= 4 ? (
					<Estrella fill={theme.palette.primary.star} />
				) : (
					<EstrellaO fill={theme.palette.primary.star} />
				)}
			</Box>
			<Box
				style={{
					width: 23,
				}}
				onClick={() => setEstrelles(5)}
			>
				{stars >= 5 ? (
					<Estrella fill={theme.palette.primary.star} />
				) : (
					<EstrellaO fill={theme.palette.primary.star} />
				)}
			</Box>
		</Box>
	);
}

Stars.propTypes = {
	stars: PropTypes.number,
	setEstrelles: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
	wrapStars: {
		display: "flex",
		flexDirection: "row",
	},
	text: {
		color: "white",
	},
}));
