import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React from "react";

export default function Home() {
	const classes = useStyles();
	return (
		<Box className={classes.root}>
			<Typography>Inici</Typography>
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		background: "rgb(15,89,45)",
		background: "linear-gradient(0deg, rgba(15,89,45,1) 0%, #4FB543 100%)",
		display: "flex",
		flex: 1,
		height: "100vh",
		overflow: "hidden",
		width: "100%",
		padding: 30,
	},
}));
