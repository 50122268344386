import * as React from "react";
import { FormControl, InputLabel, Select } from "@mui/material";
import PropTypes from "prop-types";

export default function CustomSelect({ register, name, label, elements }) {
	return (
		<FormControl fullWidth style={{ marginTop: 20 }}>
			<InputLabel shrink={true} htmlFor={name}>
				{label}
			</InputLabel>
			<Select
				native
				label={label}
				name={name}
				{...register(name)}
				notched={true}
			>
				<option value=""></option>
				{elements?.map((item) => (
					<option key={item.id} value={item.id}>
						{item.nom}
					</option>
				))}
			</Select>
		</FormControl>
	);
}

CustomSelect.propTypes = {
	register: PropTypes.func,
	label: PropTypes.string,
	name: PropTypes.string,
	elements: PropTypes.array,
};
