import React from "react";
import { Avatar, IconButton } from "@mui/material";
import { Edit } from "react-feather";
import { useNavigate } from "react-router";
import moment from "moment";

const EquipsColumns = (activitats) => {
	const navigate = useNavigate();
	const columns = [
		{
			name: "id",
			label: "ID",
			options: {
				filter: false,
				sort: true,
				display: false,
			},
		},
		{
			name: "foto",
			label: "Foto",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) => (
					<Avatar
						variant="circular"
						src={
							value &&
							"https://greenchallenge.covcontrol.net/public/storage/" +
								value
						}
					/>
				),
			},
		},
		{
			name: "nom",
			label: "Nom",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
			},
		},
		{
			name: "num_reserva",
			label: "Nº de reserva",
			options: {
				filter: true,
				sort: true,
			},
		},

		{
			name: "email",
			label: "E-mail",
			options: {
				filter: true,
				sort: true,
				sortOrder: "desc",
			},
		},
		{
			name: "data_in",
			label: "Data entrada",
			options: {
				filter: true,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) => moment(value).format("DD/MM/YYYY"),
			},
		},
		{
			name: "data_out",
			label: "Data sortida",
			options: {
				filter: true,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) => moment(value).format("DD/MM/YYYY"),
			},
		},
		{
			name: "estrelles",
			label: "Estrelles",
			options: {
				filter: true,
				sort: true,
				sortOrder: "desc",
			},
		},
		{
			name: "id",
			label: "Accions",
			options: {
				filter: false,
				sort: false,
				empty: true,
				customBodyRenderLite: (dataIndex) => {
					return (
						<IconButton
							onClick={() =>
								navigate(
									`/admin/activitats/edit/${activitats[dataIndex].id}`,
									{
										state: activitats[dataIndex],
									}
								)
							}
						>
							<Edit />
						</IconButton>
					);
				},
			},
		},
	];
	return columns;
};

export default EquipsColumns;
