import React, { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { ReactComponent as Logo } from "../assets/logo_ombres.svg";
import logo from "../assets/logo_ombres.png";
import {
	AppBar,
	Box,
	Hidden,
	IconButton,
	SvgIcon,
	Toolbar,
	Typography,
} from "@mui/material";
import { Input, Menu } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
	root: {},
	icon: {
		color: "white",
		width: "90px !important",
		height: "60px !important",
	},
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
	const classes = useStyles();
	let navigate = useNavigate();

	const logOut = () => {
		localStorage.removeItem("user");
		navigate("/");
	};

	return (
		<AppBar
			className={clsx(classes.root, className)}
			elevation={0}
			{...rest}
			color="secondary"
		>
			<Toolbar>
				<RouterLink to="/" style={{ textDecoration: "none" }}>
					<Box display={"flex"} alignItems="center">
						<img src={logo} width={30} />
						<Typography
							variant="h5"
							color="white"
							sx={{ marginLeft: 1 }}
						>
							Green Challenge
						</Typography>
					</Box>
				</RouterLink>
				<Box flexGrow={1} />
				<Hidden>
					<IconButton color="inherit" onClick={() => logOut()}>
						<Input />
					</IconButton>
				</Hidden>
				<Hidden lgUp>
					<IconButton
						color="inherit"
						onClick={() => onMobileNavOpen()}
					>
						<Menu />
					</IconButton>
				</Hidden>
			</Toolbar>
		</AppBar>
	);
};

TopBar.propTypes = {
	className: PropTypes.string,
	onMobileNavOpen: PropTypes.func,
};

export default TopBar;
