import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import { Check, Eye } from "react-feather";
import { useNavigate } from "react-router";
import moment from "moment";
import "moment/locale/ca";

const IncidencesColumns = ({ incidences, setIncidenceID, setOpen }) => {
	const columns = [
		{
			name: "id",
			label: "ID",
			options: {
				filter: false,
				sort: true,
				display: false,
			},
		},

		{
			name: "resposta",
			label: "Respost",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) => value && <Check />,
			},
		},
		{
			name: "assumpte",
			label: "Assumpte",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
			},
		},

		{
			name: "missatge",
			label: "Missatge",
			options: {
				filter: true,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) => value.substring(0, 20) + "...",
			},
		},
		{
			name: "equip.nom",
			label: "Equip",
			options: {
				filter: true,
				sort: true,
				sortOrder: "desc",
			},
		},
		{
			name: "created_at",
			label: "Data publicació",
			options: {
				filter: true,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) =>
					moment(value).locale("ca").fromNow(),
			},
		},
		{
			name: "id",
			label: "Accions",
			options: {
				filter: false,
				sort: false,
				empty: true,
				customBodyRenderLite: (dataIndex) => {
					return (
						<Tooltip title="Revisar i modificar">
							<IconButton
								onClick={() => {
									setOpen(true);
									setIncidenceID(incidences[dataIndex].id);
								}}
							>
								<Eye />
							</IconButton>
						</Tooltip>
					);
				},
			},
		},
	];
	return columns;
};

export default IncidencesColumns;
