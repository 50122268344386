import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import { Eye } from "react-feather";
import "moment/locale/ca";

const PuntColumns = ({ punts, setPuntID, setOpen }) => {
	const columns = [
		{
			name: "id",
			label: "ID",
			options: {
				filter: false,
				sort: true,
				display: false,
			},
		},
		{
			name: "nom",
			label: "Nom",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
			},
		},

		{
			name: "punt_categoria.nom",
			label: "Categoria",
			options: {
				filter: true,
				sort: true,
				sortOrder: "desc",
			},
		},
		{
			name: "municipi",
			label: "Municipi",
			options: {
				filter: true,
				sort: true,
				sortOrder: "desc",
			},
		},
		{
			name: "email",
			label: "E-mail",
			options: {
				filter: true,
				sort: true,
				sortOrder: "desc",
			},
		},
		{
			name: "id",
			label: "Accions",
			options: {
				filter: false,
				sort: false,
				empty: true,
				customBodyRenderLite: (dataIndex) => {
					return (
						<Tooltip title="Modificar">
							<IconButton
								onClick={() => {
									setOpen(true);
									setPuntID(punts[dataIndex].id);
								}}
							>
								<Eye />
							</IconButton>
						</Tooltip>
					);
				},
			},
		},
	];
	return columns;
};

export default PuntColumns;
