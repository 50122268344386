import { Button, IconButton, Tooltip, Typography } from "@mui/material";
import { makeStyles, ThemeProvider } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import Loading from "../../components/Loading";
import MUIDataTable from "mui-datatables";
import TableOptions from "../../components/TableOptions";
import { getElements } from "../../database/API";
import Title from "../../components/Title";
import getMuiTheme from "../../theme/TableStyle";
import IncidencesColumns from "./Table/IncidencesColumns";
import DialogIncidences from "./Components/DialogIncidences";
import { RefreshCcw } from "react-feather";

export default function Incidences() {
	const classes = useStyles();
	const [incidences, setIncidences] = useState([]);
	const [loading, setLoading] = useState(true);
	const [update, setUpdate] = useState(0);
	const [open, setOpen] = useState(false);
	const [incidenceID, setIncidenceID] = useState(null);
	const columns = IncidencesColumns({ incidences, setIncidenceID, setOpen });

	useEffect(() => {
		setLoading(true);
		const get = async () => {
			const { result } = await getElements("incidences");
			setIncidences(result);
			setLoading(false);
		};

		get();
	}, [update]);

	const CustomToolbar = () => {};
	const options = TableOptions(CustomToolbar);

	const nou = () => {
		setIncidenceID();
		setOpen(true);
	};

	return (
		<Box className={classes.root}>
			<Title
				title="Incidències"
				button={
					<Tooltip title="Refresca">
						<IconButton
							onClick={() => setUpdate((prev) => prev + 1)}
						>
							<RefreshCcw />
						</IconButton>
					</Tooltip>
				}
			/>
			<Loading loading={loading}>
				<Box p={3}>
					<ThemeProvider theme={getMuiTheme()}>
						<MUIDataTable
							data={incidences}
							columns={columns}
							options={options}
						/>
					</ThemeProvider>
				</Box>
			</Loading>
			<DialogIncidences
				open={open}
				setOpen={setOpen}
				id={incidenceID}
				setUpdate={setUpdate}
			/>
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		zIndex: 1,
		height: "100vh",
	},
}));
