import React from "react";
import { Box, CircularProgress, Fade } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
	loading: {
		position: "absolute",
		top: 0,
		bottom: 0,
		right: 0,
		left: 120,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		zIndex: 0,
	},
}));

export default function Loading({ loading, children }) {
	const classes = useStyles();
	return (
		<Box>
			{!loading ? (
				<Fade in={!loading}>
					<div>{children}</div>
				</Fade>
			) : (
				<Box className={classes.loading}>
					<Fade in={loading}>
						<CircularProgress color="inherit" />
					</Fade>
				</Box>
			)}
		</Box>
	);
}

Loading.propTypes = {
	loading: PropTypes.bool,
	children: PropTypes.object,
};
