import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { AlertCircle, ShoppingBag } from "react-feather";
import NavBarItem from "./NavBarItem";
import { Box, Drawer, Hidden, List } from "@mui/material";
import { ChatBubbleOutlined, FormatQuote } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { ReactComponent as inici } from "../assets/icons/green.svg";
import { ReactComponent as incidencies } from "../assets/icons/incidencies.svg";
import { ReactComponent as perfil } from "../assets/icons/perfil.svg";
import { ReactComponent as estrella } from "../assets/icons/estrella.svg";
import { ReactComponent as mapa } from "../assets/icons/mapa.svg";

const useStyles = makeStyles((theme) => ({
	mobileDrawer: {
		width: 256,
	},
	desktopDrawer: {
		width: 256,
		top: "64px !important",
		height: "calc(100% - 64px)",
		borderTopRightRadius: 10,
	},
}));

const NavBar = ({ onMobileClose, openMobile }) => {
	const classes = useStyles();

	const items = [
		{
			href: "/",
			icon: inici,
			title: "Inici",
		},
		{
			href: "/equips",
			icon: perfil,
			title: "Equips",
		},
		{
			href: "/posts",
			icon: estrella,
			title: "Publicacions",
		},
		{
			href: "/punts",
			icon: mapa,
			title: "Punts d'interès",
		},
		{
			href: "/incidencies",
			icon: incidencies,
			title: "Incidències",
		},
	];

	const content = (
		<Box height="100%" display="flex" flexDirection="column">
			<Box p={2}>
				<List>
					{items?.map((item) => (
						<NavBarItem
							href={item.href}
							key={item.title}
							title={item.title}
							icon={item.icon}
							onClose={onMobileClose}
						/>
					))}
				</List>
			</Box>
			<Box flexGrow={1} />
		</Box>
	);

	return (
		<>
			<Hidden lgUp>
				<Drawer
					anchor="left"
					classes={{ paper: classes.mobileDrawer }}
					onClose={onMobileClose}
					open={openMobile}
					variant="temporary"
				>
					{content}
				</Drawer>
			</Hidden>
			<Hidden mdDown>
				<Drawer
					anchor="left"
					classes={{ paper: classes.desktopDrawer }}
					open
					variant="persistent"
				>
					{content}
				</Drawer>
			</Hidden>
		</>
	);
};

NavBar.propTypes = {
	onMobileClose: PropTypes.func,
	openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
	onMobileClose: () => {},
	openMobile: false,
};

export default NavBar;
