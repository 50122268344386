import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Input,
	TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
	createPunt,
	eliminarPost,
	getElement,
	getElements,
	publicar,
	updatePunt,
} from "../../../database/API";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import CustomTextField from "../../../components/CustomTextField";
import CustomSelect from "../../../components/CustomSelect";

export default function DialogPunt({ open, setOpen, id, setUpdate }) {
	const classes = useStyles();
	const [punt, setPunt] = useState([]);
	const [categories, setCategories] = useState([]);
	const [loading, setLoading] = useState(false);
	const [publicat, setPublicat] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();

	useEffect(() => {
		const get = async () => {
			setLoading(true);
			if (id) {
				console.log(id);
				const { result } = await getElement("punt", id);
				setPunt(result);
				reset(result);
			} else {
				console.log("RESET");
				reset({});
			}
			const { result } = await getElements("punt_categories");
			setCategories(result);
			setLoading(false);
		};
		get();
	}, [id]);

	const guardar = async (values) => {
		console.log(values);
		const message = await updatePunt(values, id);
		setOpen(false);
		setUpdate((prev) => prev + 1);
		reset({});
		enqueueSnackbar(message, {
			variant: "success",
		});
	};

	const crear = async (values) => {
		console.log(values);
		const message = await createPunt(values);
		setOpen(false);
		setUpdate((prev) => prev + 1);
		reset();
		enqueueSnackbar(message, {
			variant: "success",
		});
	};

	const eliminar = async () => {
		const message = await eliminarPost(post?.id, post?.equip?.num_reserva);
		setOpen(false);
		setUpdate((prev) => prev + 1);
		enqueueSnackbar(message, {
			variant: "success",
		});
	};

	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			maxWidth="xl"
		>
			<DialogTitle id="alert-dialog-title">
				{id ? "Editar punt d'interès" : "Crear punt d'interès"}
			</DialogTitle>
			<form onSubmit={handleSubmit(id ? guardar : crear)}>
				<DialogContent>
					{loading ? (
						<Box display={"flex"} justifyContent="center">
							<CircularProgress />
						</Box>
					) : (
						<Box>
							<Grid container spacing={3}>
								<Grid item md={4}>
									<CustomTextField
										name="nom"
										label="Nom"
										type="text"
										errors={errors.nom}
										register={register}
									/>
								</Grid>
								<Grid item md={4}>
									<CustomSelect
										elements={categories}
										name="punt_categoria_id"
										label="Categoria"
										register={register}
									/>
								</Grid>
								<Grid item md={4}>
									<CustomTextField
										name="municipi"
										label="Municipi"
										type="text"
										errors={errors.municipi}
										register={register}
									/>
								</Grid>
							</Grid>
							<Grid container spacing={3}>
								<Grid item md={6}>
									<CustomTextField
										name="lat"
										label="Latitud"
										type="text"
										errors={errors.lat}
										register={register}
									/>
								</Grid>
								<Grid item md={6}>
									<CustomTextField
										name="lon"
										label="Longitud"
										type="text"
										errors={errors.lon}
										register={register}
									/>
								</Grid>
							</Grid>

							<Grid container spacing={3} mt={0}>
								<Grid item md={12}>
									<TextField
										label="Descripcio"
										type="text"
										multiline
										rows={3}
										variant="outlined"
										fullWidth
										{...register("descripcio")}
									/>
								</Grid>
							</Grid>
							<Grid container spacing={3} mt={0}>
								<Grid item md={6}>
									<TextField
										label="Sostenibilitat"
										type="text"
										multiline
										rows={3}
										variant="outlined"
										fullWidth
										{...register("sostenibilitat")}
									/>
								</Grid>
								<Grid item md={6}>
									<TextField
										label="Espai polivalent"
										type="text"
										multiline
										rows={3}
										variant="outlined"
										fullWidth
										{...register("polivalent")}
									/>
								</Grid>
							</Grid>

							<Grid container spacing={3}>
								<Grid item md={12}>
									<CustomTextField
										name="activitat"
										label="Activitat"
										type="text"
										errors={errors.lat}
										register={register}
									/>
								</Grid>
							</Grid>

							<Grid container spacing={3}>
								<Grid item md={6}>
									<CustomTextField
										name="telefon"
										label="Telèfon"
										type="text"
										errors={errors.lat}
										register={register}
									/>
								</Grid>
								<Grid item md={6}>
									<CustomTextField
										name="email"
										label="E-mail"
										type="text"
										errors={errors.lon}
										register={register}
									/>
								</Grid>
							</Grid>
						</Box>
					)}
				</DialogContent>
				<DialogActions>
					{id && (
						<Button onClick={eliminar} color="warning">
							Eliminar publicació
						</Button>
					)}
					<Button onClick={() => setOpen(false)} color="warning">
						Tancar
					</Button>
					<Button type="type" autoFocus>
						Guardar
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
}

DialogPunt.propTypes = {
	open: PropTypes.bool,
	setOpen: PropTypes.func,
	setUpdate: PropTypes.func,
	id: PropTypes.number,
};

const useStyles = makeStyles((theme) => ({
	root: {
		background: "rgb(15,89,45)",
		background: "linear-gradient(0deg, rgba(15,89,45,1) 0%, #4FB543 100%)",
		height: "100vh",
		width: "100%",
	},
}));
